h1,
.h1 {
  font-size: 2rem;
  line-height: $line-height;
  font-weight: bold;

  @media (min-width: $desktop-sd) {
    font-size: 3rem;
  }
}

h2,
.h2 {
  font-size: 1.5rem;
  line-height: $line-height;
  font-weight: bold;

  @media (min-width: $desktop-sd) {
    font-size: 2.25rem;
  }
}

h3,
.h3 {
  font-size: 1.25rem;
  line-height: $line-height;
  font-weight: bold;

  @media (min-width: $desktop-sd) {
    font-size: 1.5rem;
  }
}

i {
  font-style: italic;
}

.subtitle {
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: 700;
  line-height: 2.2;
}
