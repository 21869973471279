@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.RecommendedList {
  padding: 2rem 0 20px;
  background: $blocksBg;
  margin: 0 auto;
  @media (min-width: $tablet) {
    padding: 2rem 0 25px;
  }

  @media (min-width: $desktop-hd) {
    padding: 40px 0 25px;
  }

  & .Container {
    padding: 0 16px;
    max-width: 1280px;
    margin: 0 auto;
    @media (min-width: $tablet) {
      padding: 0 32px;
    }
    @media (min-width: $desktop-hd) {
      padding: 0;
    }
  }

  &__heading {
    @include typography(h2, $bold);
    margin-bottom: 34px;
    @media (min-width: $desktop-hd) {
      margin-bottom: 26px;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8px;
    padding-bottom: 2rem;

    @media (min-width: $tablet) {
      grid-template-columns: repeat(4, 1fr);
      column-gap: 20px;
    }

    @media (min-width: $desktop-sd) {
      column-gap: 28px;
      grid-template-columns: repeat(6, 1fr);
    }

    & .CardsItem__wrapper {
      @media (min-width: $tablet) {
        margin-bottom: 25px;
      }
    }

    .CardsItem {
      &:nth-of-type(even) {
        top: 2rem;
        @media (min-width: $desktop-hd) {
          top: 38px;
        }
      }

      &__poster {
        margin-bottom: 0;
      }
    }

    .CardsItemSkeleton.Skeleton {
      &:nth-of-type(even) {
        position: relative;
        margin-bottom: 12px;
        top: 2rem;
        @media (min-width: $desktop-hd) {
          top: 38px;
        }
      }
    }
  }
}
