@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.CardsSlider__slider .ChannelCardWrap {
  margin: 0 4px;

  @media (min-width: $tablet) {
    margin: 0 10px;
  }
  @media (min-width: $desktop-hd) {
    margin: 0 14px;
  }
}

.ChannelsSlider {
  &__container {
    padding: 0;
  }

  &__button-group {
    top: -10rem;
  }

  .ChannelCardSkeleton.Skeleton {
    margin: 0 4px;

    @media (min-width: $tablet) {
      margin: 0 10px;
    }

    @media (min-width: $desktop-hd) {
      margin: 0 14px;
    }
  }

  .ChannelCardSkeleton,
  .ChannelCard {
    vertical-align: middle;
    margin: 0;
  }
}
