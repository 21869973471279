@import-normalize;
@import 'resets';
@import 'fonts';
@import 'variables';
@import 'mixins';
@import 'typography';
@import 'layouts';
@import 'buttons';
@import 'skeletons';
@import '~react-toastify/dist/ReactToastify.css';
@import '../../components/common/Tabs/Tabs';
@import '../../components/subscriptions/subscription-card/SubscriptionCard';
@import '../../components/subscriptions/subscriptions-list/SubscriptionsList';
@import '../../components/subscriptions/tariff-card/TariffCard';
@import '../../components/channels/channels-subscription-list/ChannelsSubscriptionList';

html {
  padding: env(safe-area-inset);
}

@-moz-document url-prefix() {
  .scrollbar-thin {
    scrollbar-width: thin;
  }
  .scrollbar-thin * {
    scrollbar-width: thin;
  }
}

* {
  scrollbar-color: $secondaryText;
  &::-webkit-scrollbar-track {
    background-color: $disabledButton;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: $disabledButton;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $secondaryText;
  }
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.25;
  font-style: normal;
  font-weight: normal;
  font-size: $font-size;
  color: #fff;
  background-color: $background;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /**
 Used to define the position of the ToastContainer
 https://fkhadra.github.io/react-toastify/how-to-style
 **/
  .Toastify__toast {
    min-height: $header-height-mobile;

    @media (min-width: $desktop-sd) {
      min-height: $header-height-desktop;
    }

    &-container--top-right {
      right: 0 !important;
      top: $header-height-mobile;

      @media (min-width: $desktop-sd) {
        top: $header-height-desktop;
      }
    }
  }
}
