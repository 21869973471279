@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.page-header__banner {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 0;
  transition: height 0.3s;
  padding: 0px 16px;

  &.show {
    height: initial;
    padding: 8px 16px;
    height: 56px;
  }

  @media (min-width: $tablet) {
    display: none;
  }
}

.page-header__banner__btn-link {
  margin-left: auto;
}

.page-header__banner-close-btn {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg path {
    fill: $mainText;
  }
}

.page-header__banner__btn-link {
  @include secondaryButton();

  &:hover {
    @include secondaryButtonHover;
  }
}

.page-header__banner__logo__description {
  & .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  & .subtitle {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    line-height: initial;
  }
}
