@import '../../../assets/styles/variables';

.LoginForm {
  & .Form__group {
    margin-bottom: 24px;

    &:nth-child(2) {
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & .Form__options {
    margin-bottom: 0;
  }
  & .Form__option {
    margin-bottom: 24px;
  }
}
