@import '../../../assets/styles/variables';

.Avatar {
  display: grid;
  place-items: center;
  font-size: rem(14px);
  line-height: $line-height;
  text-transform: uppercase;
  color: $white;
  border-radius: 50%;
  background-image: $gradient-aquamarine;
  overflow: hidden;
  position: relative;
  width: 32px;
  height: 32px;

  @media (min-width: $tablet) {
    width: 36px;
    height: 36px;
  }

  // &:hover {
  //   &::before {
  //     content: '';
  //     position: absolute;
  //     top: -2px;
  //     left: -2px;
  //     right: -2px;
  //     bottom: -2px;
  //     background-color: #000000;
  //     opacity: 0.5;
  //   }
  // }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}
