@import 'variables';

// px to rem
@function rem($size) {
  @return $size / 16px * 1rem;
}

// riple effect
@mixin ripple-effect($color: #fff) {
  background-position: center;
  transition: background 0.8s;

  &:hover {
    background: $color radial-gradient(circle, transparent 1%, $color 1%) center/15000%;
  }

  &:active {
    background-color: $color;
    background-size: 100%;
    transition: background 0s;
  }
}

@mixin secondaryButton() {
  color: $accent;
  border: 1px solid $accent;
  padding: 10px 24px;
  font-size: 16px;
  font-weight: 500;
  background: none;
  border-radius: 8px;
}

@mixin secondaryButtonHover() {
  color: $mainText;
  background: $accent;
  transition: all 0.3s;
}

@mixin secondaryButtonDisable() {
  color: $disabledButton;
  background: none;
  border: 1px solid $disabledButton;
}

@mixin teriaryButton() {
  background: none;
  box-shadow: none;
  border: none;
  color: $accent;
  transition: all 0.3s;
  font-size: 16px;
  font-weight: 500;
  padding: 14px 24px;
  margin: 0 auto;
  display: block;
}

@mixin teriaryButtonHover() {
  background: #00b8b84d;
}

@mixin teriaryButtonDisable() {
  background: none;
  opacity: 0.2;
  color: $mainText;
}

@mixin typography($type, $fontWeight) {
  @if $type == 'h1' {
    font-family: $font-family;
    font-style: normal;
    font-weight: $fontWeight;
    font-size: 32px;
    line-height: 38px;
    @media (min-width: $desktop-sd) {
      font-size: 42px;
      line-height: 49px;
    }
  }
  @if $type == 'h2' {
    font-family: $font-family;
    font-style: normal;
    font-weight: $fontWeight;
    font-size: 22px;
    line-height: 26px;
    @media (min-width: $desktop-sd) {
      font-size: 36px;
      line-height: 42px;
    }
  }

  @if $type == 'h3' {
    font-family: $font-family;
    font-style: normal;
    font-weight: $fontWeight;
    font-size: 20px;
    line-height: 23px;
    @media (min-width: $desktop-sd) {
      font-size: 28px;
      line-height: 33px;
    }
  }
  @if $type == 'h4' {
    font-family: $font-family;
    font-style: normal;
    font-weight: $fontWeight;
    font-size: 19px;
    line-height: 22px;
    @media (min-width: $desktop-sd) {
      font-size: 24px;
      line-height: 28px;
    }
  }
  @if $type == 'h5' {
    font-family: $font-family;
    font-style: normal;
    font-weight: $fontWeight;
    font-size: 18px;
    line-height: 21px;
    @media (min-width: $desktop-sd) {
      font-size: 20px;
      line-height: 23px;
    }
  }
  @if $type == 'subtitle' {
    font-family: $font-family;
    font-style: normal;
    font-weight: $fontWeight;
    font-size: 16px;
    line-height: 19px;
    @media (min-width: $desktop-sd) {
      font-size: 16px;
      line-height: 19px;
    }
  }
  @if $type == 'base' {
    font-family: $font-family;
    font-style: normal;
    font-weight: $fontWeight;
    font-size: 16px;
    line-height: 150%;
    @media (min-width: $desktop-sd) {
      font-size: 16px;
      line-height: 150%;
    }
  }
  @if $type == 'subtitleMd' {
    font-family: $font-family;
    font-style: normal;
    font-weight: $fontWeight;
    font-size: 16px;
    line-height: 19px;
    @media (min-width: $desktop-sd) {
      font-size: 18px;
      line-height: 21px;
    }
  }
  @if $type == 'subtitleSm' {
    font-family: $font-family;
    font-style: normal;
    font-weight: $fontWeight;
    font-size: 14px;
    line-height: 16px;
    @media (min-width: $desktop-sd) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  @if $type == 'baseSm' {
    font-family: $font-family;
    font-style: normal;
    font-weight: $fontWeight;
    font-size: 14px;
    line-height: 150%;
    @media (min-width: $desktop-sd) {
      font-size: 14px;
      line-height: 150%;
    }
  }
  @if $type == 'description' {
    font-family: $font-family;
    font-style: normal;
    font-weight: $fontWeight;
    font-size: 12px;
    line-height: 14px;
    @media (min-width: $desktop-sd) {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
