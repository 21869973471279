@import '../../../assets/styles/variables';

%icon-clickable {
  position: relative;
  cursor: pointer;
}

%nav-item-text {
  display: flex;
  align-items: center;
  height: 100%;
  text-transform: uppercase;
  font-weight: bold;
  color: $nav-link-color;
  transition: $nav-link-transition;
  font-size: 13px;

  @media (min-width: 1200px) {
    font-size: 14px;
  }

  svg path {
    transition: $nav-link-transition;
  }

  &.active {
    color: $nav-link-active-color;
    svg path {
      fill: $nav-link-active-color;
    }
  }

  &:hover {
    color: $accentHover;

    svg path {
      fill: $nav-link-hover-color;
    }
  }
}

.Header {
  z-index: 99;
  position: sticky;
  top: 0;
  background: $blocksBg;
  height: $header-height-mobile;

  &::after {
    content: '';
    height: 1px;
    background: $disabledButton;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  @media (min-width: $tablet) {
    height: $header-height-desktop;
  }

  ul {
    list-style-type: none;
  }

  nav {
    a {
      @extend %nav-item-text;
    }
  }

  &Container {
    display: flex;
    align-items: center;
    height: inherit;

    @media (min-width: $desktop-sd) {
      align-items: normal;
    }
  }

  &Logo {
    display: flex;
    align-items: center;
    max-width: 4.5rem;
    z-index: 1;

    @media (min-width: $tablet) {
      max-width: initial;
    }

    svg {
      max-width: 100%;
    }
  }

  &Nav {
    margin-left: 1.5rem;
    flex-grow: 1;
    display: block;

    @media (min-width: $desktop-sd) {
      margin-left: 70px;
    }
  }

  // Header ToolBar
  .ToolBar {
    &__item {
      & > a {
        @extend %nav-item-text;

        svg {
          @extend %icon-clickable;
        }

        span {
          padding-left: 0.5rem;
          position: relative;
        }
      }
    }

    &__login {
      & .Avatar:after {
        display: none;
      }
    }
  }
}

.HeaderContainer.Container {
  padding: 0 16px;
  width: 100vw;
  max-width: 1280px;
  justify-content: space-between;

  @media (min-width: $tablet) {
    padding: 0 32px;
  }

  @media (min-width: $desktop-hd) {
    padding: 0;
  }
}

.HeaderLogo:hover {
  & svg path:first-child {
    transition: all 0.25s;
    fill: $accentHover;
  }
  & svg path {
    transition: all 0.25s;
    fill: $secondaryText;
  }
}

.page-header__banner.show + .Header .SideBar.with-scrollbar {
  top: 56px;
  bottom: 0;
  height: initial;
}
