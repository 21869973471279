@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.SearchBar {
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  right: 16px;
  left: 16px;
  @media (min-width: $mobile-landscape) {
    right: 0;
    left: initial;
    width: calc(100vw - 8rem);
    max-width: rem(324px);
  }

  &__form form {
    padding: 1px;
    border-radius: 10px;
    background: $accent;
  }

  &__input {
    position: relative;
    z-index: 1;
    height: 2.5rem;
    margin: 0;
    width: 100%;
    padding: 0.5rem rem(45px);
    background: $buttonsBg;
    border-radius: 9px;
    color: $mainText;
    border: none;
  }

  &__btn {
    cursor: pointer;
    position: absolute;
    top: 0;
    margin: 0;
    padding: 0;
    width: rem(40px);
    height: rem(40px);
    border: 0;
    background: transparent none;

    &--submit {
      z-index: 1;
      left: 0;
      & path {
        fill: $mainText;
      }
    }

    &--close {
      right: 0;
      z-index: 1;
    }

    svg {
      position: absolute;
      top: 22%;
      left: 20%;
    }

    &:hover {
      path {
        fill: $accentHover;
      }
    }
  }

  &__img-wrap {
    padding: 1px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.3)
    );
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    width: 64px;
    height: 92px;
    flex-shrink: 0;
  }

  &__list-wrap {
    position: absolute;
    top: calc(100% + 1rem);
    left: 0;
    z-index: 1;
    width: 100%;
    visibility: hidden;
    transition: all $transition;
    opacity: 0;
    padding: 1px;
    overflow: hidden;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.3)
    );
    border-radius: 10px;

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }

  &__list {
    background-color: $blocksBg;
    border-radius: 9px;
    padding-top: 5px;

    &-title {
      padding: rem(12px) 1rem;
      font-size: rem(14px);
      font-weight: 500;
      line-height: $line-height;
      color: $grey;
      cursor: initial;

      strong {
        font-weight: bold;
      }
    }

    &-items {
      max-height: rem(260px);
      overflow: hidden;
      overflow-y: scroll;
      border-bottom: 1px solid $disabledButton;
    }

    &-item {
      display: flex;
      align-items: center;
      padding: rem(6px) 1rem;
      margin-bottom: rem(6px);
      transition: background-color $transition;
      &:first-child {
        border-radius: 9px 0 0 0;
      }

      &:hover {
        background-color: $secondaryItems;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
      }

      &-content {
        flex: 1;
        margin-left: 0.5rem;
        max-height: 104px;

        &-purchase {
          color: $secondAccent;
        }

        &-subscription {
          color: $accent;
        }

        span {
          display: block;
          font-size: 12px;
          line-height: $line-height;
          margin-bottom: 4px;
          width: 100%;
          height: initial;
          border-radius: 0;

          &:first-of-type {
            @include typography(subtitle, $medium);
            width: 200px;
            color: $mainText;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: initial;
            margin-bottom: 4px;
          }

          &:nth-of-type(2) {
            color: $secondaryText;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    &-link {
      display: block;
      padding: 1rem;
      background-color: $blocksBg;
      color: $aqua;
      font-weight: bold;
      font-size: rem(14px);
      line-height: $line-height;
      transition: all $transition;
      border-radius: 9px;
      text-align: center;

      &:hover {
        color: $aqua-dark;
      }
    }
  }
}
