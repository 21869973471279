@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.TariffCardList {
  margin: 0 0 60px;
  &:not(.SubscriptionsCardList) {
    @media (min-width: $mobile-landscape) {
      display: grid;
      grid-template-columns: 48% 48%;
      grid-gap: 2% 4%;
      margin: 0 0 100px;
    }
  }

  @media (min-width: $tablet) {
    grid-template-columns: 32% 32% 32%;
    grid-gap: 2% 2%;
    &.SubscriptionsCardList {
      display: grid;
      grid-template-columns: auto;
      grid-gap: 3% 2%;
      margin: 0 0 100px;
    }
  }

  &:not(.SubscriptionsCardList) {
    @media (min-width: $desktop-sd) {
      display: none;
    }
  }

  &.SubscriptionsCardList {
    .TariffCard {
      margin-left: 0;
      margin-right: 0;
      @media (min-width: $tablet) {
        width: 60%;
      }
    }
    @media (min-width: $desktop-sd) {
      grid-template-columns: 32% 32% 32%;
      .TariffCard {
        width: auto;
        min-width: 0;
      }
    }
  }
}

.TariffsGridSection__button-group,
.TariffsGridSection__slider {
  @media (min-width: $mobile) {
    display: none;
  }

  @media (min-width: $desktop-sd) {
    display: flex;
  }
}

.TariffCard__flex-container {
  display: flex;
  justify-content: space-between;

  &.with-button {
    margin-bottom: 24px;
  }
}

.tariff-info__wrapper {
  @media (min-width: $tablet) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
}

.TariffCard {
  $self: &; // ref to component (&)
  position: relative;
  padding: 24px 20px;
  margin-bottom: 24px;
  background-color: $blocksBg;
  border-radius: 12px;
  border: 1px solid #5a5a5a;
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet) {
    min-width: 300px;
    width: 300px;
    margin-bottom: 0;

    &:first-child {
      margin-left: 32px;
    }
    &:last-child {
      margin-right: 32px;
    }
  }

  &__title {
    margin-bottom: 12px;
    @include typography(h4, $medium);

    @media (min-width: $tablet) {
      margin-bottom: 24px;
    }
  }

  &__price {
    line-height: 0.85;
    color: $accent;

    &.orange {
      color: $secondAccent;
    }

    @media (min-width: $tablet) {
      display: none;
    }

    p {
      font-size: 42px;
      font-weight: $bold;
      display: block;
    }

    span {
      display: block;
      text-align: center;
      font-size: 12px;
    }
  }

  &__price-column {
    display: none;
    @media (min-width: $tablet) {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      color: $accent;

      &.orange {
        color: $secondAccent;
      }
    }

    p {
      font-size: 42px;
      font-weight: $bold;
      display: block;
    }

    span::before {
      content: attr(uah);
      display: block;
      margin-left: 4px;
      border-bottom: 1px solid $secondaryItems;
      font-size: 12px;
    }
    span::after {
      content: attr(month);
      margin-left: 4px;
      font-size: 12px;
    }
  }

  &__description {
    @include typography(base, $regular);
    display: flex;
    flex-direction: column;

    & span {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      @media (min-width: $tablet) {
        justify-content: center;
      }
    }

    & span::before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url('../../../assets/images/subscriptions/check.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 17px 17px;
      margin-right: 3px;
    }

    span.empty-content {
      display: none;

      @media (min-width: $tablet) {
        display: block;
        position: relative;
        height: 24px;
        order: 2;
        &::before {
          content: '';
          position: absolute;
          display: block;
          height: 2px;
          width: 60px;
          top: calc(50% - 1px);
          right: calc(50% - 30px);
          background: $secondaryItems;
          margin-right: 0;
        }
      }
    }
  }

  &__available-until {
    @include typography(description, $regular);
    text-align: left;
    margin-top: 10px;

    @media (min-width: $tablet) {
      text-align: center;
    }
  }

  &__manage-via {
    @include typography(description, $regular);
    text-align: left;
    margin-top: 8px;
    color: $secondaryText;

    @media (min-width: $tablet) {
      text-align: center;
    }

    &:before {
      content: '';
      width: 14px;
      height: 14px;
      background: no-repeat 0 0 transparent;
      display: inline-block;
      vertical-align: bottom;
      margin-right: 7px;
    }

    &.googlePlay:before {
      background-image: url('../../../assets/images/subscriptions/googlePlay.png');
    }
    &.appleStore:before {
      background-image: url('../../../assets/images/subscriptions/appStore.png');
    }

    &:empty {
      margin-top: 0;
      &:before {
        display: none;
      }
    }
  }

  & .Btn {
    margin-top: 0;
    height: 47px;

    &--primary {
      background: transparent;
      color: $accent;
      border: 1px solid $accent;

      &:hover {
        background: $accent;
        color: $mainText;
      }
    }

    &:disabled {
      background: $disabledButton;
      color: rgba(255, 255, 255, 0.2);
      opacity: 1;
      border-style: none;
    }
  }
}

.Skeleton.tariff-card-skeleton {
  margin-bottom: 24px;
  height: 237px;
  @media (min-width: $tablet) {
    margin-bottom: 0;
    height: initial;
    min-width: 300px;
    width: 60%;
    min-height: 306px;
  }

  @media (min-width: $desktop-sd) {
    margin-bottom: 0;
    min-height: 312px;
    width: auto;
    min-width: 0;
  }
}
