@import 'variables';
@import 'mixins';

#root,
#AppContainer {
  font: $primary-font;
  margin: 0 auto;
  height: 100%;
}

main {
  position: relative;
  min-height: 100vh;
}

// .webp {
//   .BgLayout {
//     background-image: linear-gradient(180deg, rgba(32, 41, 66, 0) 0%, #202942 78.39%),
//       linear-gradient(180deg, rgba(32, 41, 66, 0) 0%, #202942 78.39%),
//       url(../../assets/images/poster-bg.webp);
//   }
// }

.Page {
  padding: 2rem 0;

  @media (min-width: $desktop-sd) {
    padding: 3rem 0;
  }
}

.Container,
.container {
  margin: $container-margin;
  padding-left: 16px;
  max-width: $container-width;
  width: 100%;
  @media (min-width: $tablet) {
    padding-left: 32px;
  }
  @media (min-width: $desktop-sd) {
    padding-right: 22px;
  }

  @media (min-width: $desktop-hd) {
    padding: $container-padding-hd;
    max-width: $container-width-hd;
  }
}

// Effects
.ripple-effect {
  background-position: center;
  transition: background 0.8s;

  &:hover {
    background: $aqua radial-gradient(circle, transparent 1%, $aqua 1%) center/15000%;
  }

  &:active {
    background-color: $aqua;
    background-size: 100%;
    transition: background 0s;
  }
}

.without-scrollbar {
  scrollbar-width: none; // FF

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: none;
  }
}

.Tabs.without-scrollbar {
  position: relative;
  border-right: 16px solid transparent;
  @media (min-width: $tablet) {
    border-right-width: 32px;
  }
  @media (min-width: $desktop-hd) {
    border-right-style: none;
  }
  & .TabsBtn:last-child {
    margin-right: 0;
  }
  &::after {
    content: '';
    display: block;
    height: 2px;
    background: #2c2c2c;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
  }
}
