@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.Modal.Modal_RegisterInternalUser {
  min-width: 328px;
  max-width: 328px;
  padding: 16px;
  color: #fff;

  @media (min-width: $tablet) {
    padding: 32px;
    min-width: 470px;
    max-width: 470px;
  }
}

.Modal_RegisterInternalUser > .Modal__content {
  display: flex;
  flex-direction: column;
  align-items: center;

  & svg {
    width: 50px;
    height: 50px;
    @media (min-width: $tablet) {
      width: 96px;
      height: 96px;
    }
  }
}

.Modal_RegisterInternalUser > .Modal__close > svg > path {
  fill: #fff;
}

.Modal_RegisterInternalUser__title {
  margin-top: 32px;
  @include typography(h3, $bold);
  text-align: center;
}

.Modal_RegisterInternalUser__description {
  margin-top: 25px;
  @include typography(subtitle, $regular);
  text-align: center;
}

.RegisterInternalUser__proceed {
  width: 100%;
  margin-top: 32px;
  color: $mainText;
  background-color: $accent;
  border: none;
  border-radius: 8px;
  text-align: center;
  padding: 15px;
  @include typography(subtitle, $medium);

  &:hover {
    background-color: $accentHover;
  }
}

.Modal_RegisterInternalUser .ParentControlForm .Form__group .text-field__input {
  gap: 0;
  grid-gap: 0;

  @media (min-width: $tablet) {
    max-width: initial;
  }
}

.Modal_RegisterInternalUser .ParentControlForm .Form__group div {
  gap: 20px;
}
