@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.CardsItem {
  $self: &; // ref to component (&)
  position: relative;
  overflow: hidden;

  &__wrapper {
    padding: 1px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.3)
    );
    margin-bottom: 10px;
    border-radius: 10px;
  }

  @media (min-width: $desktop-sd) {
    &--active,
    &:hover {
      #{ $self }__wrapper {
        background: $accent;
      }

      & a .ChannelCard__favorites-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
      }

      #{ $self }__overlay {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__image {
    padding-bottom: 141%;
  }

  &__badge {
    position: absolute;
    right: rem(12px);
    bottom: rem(12px);
    min-width: 2rem;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: $b-radius;
    color: $mainText;
    @include typography(description, $medium);
    padding: 1px 2px;
    text-align: center;
    display: block;
    width: auto;
  }

  &__poster {
    user-select: none;
    position: relative;
    overflow: hidden;
    display: block;
    min-height: 224px;
    border-radius: 9px;
    background: $background;

    @media (min-width: $tablet) {
      min-height: rem(200px);
    }

    @media (min-width: $desktop-sd) {
      min-height: rem(263px);
    }
  }

  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    visibility: hidden;
    opacity: 0;
    background-color: $overlay;
    transition: opacity 0.2s, visibility 0.2s;
    // border-radius: 9px;
    // border: 1px solid $accent;

    &-title {
      margin: auto;
      display: block;
      text-align: center;
      color: $mainText;
      @include typography(baseSm, $medium);
    }

    &-play-icon {
      display: block;
      margin: 0 auto 1rem;
      width: rem(54px);
      height: rem(54px);
      background-image: url('../../../assets/images/movies/play.svg');
    }
  }

  &__image {
    img {
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 9px;
    }
  }

  &__name {
    display: block;
    margin-bottom: 4px;
    @include typography(subtitle, $bold);
    color: $mainText;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media (min-width: $desktop-sd) {
      font-size: 1rem;
    }
  }

  &__genre {
    margin-bottom: 4px;
    color: $secondaryText;
    @include typography(description, $regular);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__label {
    @include typography(description, $regular);

    &--purchase {
      color: $secondAccent;
    }

    &--subscribe {
      color: $accent;
    }
  }

  // Card item skeleton
  &Skeleton {
    &__image {
      margin-bottom: 12px;
      aspect-ratio: 1/1.405;
    }

    &__title {
      width: 90%;
      height: 15px;
      margin-bottom: 8px;
    }

    &__extra {
      width: 70%;
      height: 10px;
      margin-bottom: 8px;
    }

    &__subscription {
      width: 51%;
      height: 10px;
      margin-bottom: 4px;
    }
  }
}
