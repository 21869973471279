@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.Form {
  &__option {
    margin-bottom: 28px;
  }
  &__options {
    margin-bottom: 0;
  }
  .FormInput {
    position: relative;
    width: 100%;

    // temporary for password eye icon
    svg {
      cursor: pointer;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;

      path {
        fill: $aqua;
      }
    }

    & input::placeholder {
      @include typography(subtitleSm, $regular);
    }
  }

  &__group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-label {
      font-size: 0.75rem;
      line-height: 1.5rem;
      padding-left: 0.75rem;
      color: #313d5f;
    }

    & input::placeholder {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__text {
    color: $secondaryText;
    @include typography(subtitle, $medium);
    margin: 1rem auto;
    text-align: center;

    &-bottom {
      margin: 1rem auto;

      @media (min-width: $desktop-sd) {
        margin: 1.5rem auto 2rem;
      }
    }

    a,
    button {
      text-decoration: underline;
      color: $orange;
      border: 0;

      &:hover {
        text-decoration: none;
      }
    }

    &-privacy-note {
      font-size: rem(12px);
      line-height: 1.2;

      br {
        @media (min-width: $tablet) {
          display: none;
        }
      }

      a, button {
        color: $accent;
      }
    }
  }

  label,
  .label {
    width: 100%;
    display: inline-flex;
    font-size: rem(14px);
    line-height: 1.43;
    color: $violet-dark;
    margin-bottom: 0.5rem;
    cursor: pointer;
  }

  textarea,
  input {
    width: 100%;
    height: 2.5rem;
    padding: 0 rem(12px);
    background-color: $white;
    border: $border;
    border-radius: $b-radius;
    color: #2b303c;
    font-size: 14px;
    line-height: 16px;

    &.uppercase {
      text-transform: uppercase;
    }

    &::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      pointer-events: none;
      position: absolute;
      right: 0;
    }

    &[type='password'] + svg path {
      fill: $orange;
    }
  }

  textarea {
    padding: rem(10px) rem(12px);
    resize: none;
    overflow: hidden;

    @media (min-width: $desktop-sd) {
      padding: rem(16px) rem(12px);
    }

    &::placeholder {
      color: $secondaryText;
    }
  }

  select {
    background: $white;
    border: $border;
    border-radius: 3px;
    height: 2.5rem;
    font-size: 14px;
    line-height: 16px;
    color: #2b303c;
    padding: 0 0.5rem;
    width: 30%;
  }

  .Btn {
    padding: 14px 2rem;
    width: 100%;
  }

  // ==> input error
  .i-error {
    border: 2px solid #e43030;
  }

  // ==> input message error
  .i-m-error {
    margin-top: 5px;
    padding-left: 12px;
    z-index: 1;
    left: 0;
    top: 100%;
    display: block;
    color: #e43030;
    @include typography(description, $regular);

    a,
    button {
      text-decoration: underline;
      color: $red;
      border: 0;
      background-color: transparent;
    }
  }

  &__captcha {
    margin: 1rem auto;
    width: max-content;
    min-height: rem(78px);
  }

  &__option {
    display: flex;
    align-items: center;

    span {
      margin-right: rem(10px);
    }

    a,
    label {
      cursor: pointer;
      width: auto;
      color: $mainText;
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
  }

  label:hover {
    & .checkmark {
      border: 1px solid $mainText;
    }
  }

  /* Create a custom checkbox */
  .checkmark {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 1rem;
    width: 1rem;
    border-radius: 3px;
    border: 1px solid $secondaryText;

    svg {
      //display: none;
      fill: transparent;
    }
    &:hover {
      border: 1px solid $mainText;
    }
  }

  input[type='checkbox'] {
    display: none;

    &:checked + label {
      color: $mainText;
      display: flex;
      align-items: center;

      .checkmark {
        background-color: $accent;
        border: 1px solid $accent;
        svg {
          //display: none;
          fill: $mainText;
        }
      }
    }
  }
}

.text-field {
  &__input {
    & .MuiInputBase-root.MuiFilledInput-root::before {
      display: none;
    }
    & .MuiInputBase-root.MuiFilledInput-root::after {
      display: none;
    }

    & .MuiInputBase-root input {
      // border: 1px solid #d2d2d2;
      border: none !important;
      border-radius: 9px;
      background-color: $buttonsBg !important;
      padding: 8px 12px 0px;
      line-height: 16px !important;
      font-size: 14px !important;
      color: $mainText;
      caret-color: $mainText;

      &.Mui-disabled {
        background-color: $disabledButton !important;
      }
    }

    & .MuiInputBase-root input:focus {
      border: 1px solid $accent;
    }
    & .MuiInputBase-root.Mui-error input {
      border: 1px solid $error;
    }

    & .MuiFormLabel-root.MuiInputLabel-root {
      color: $secondaryText;
      font-size: 14px;
      letter-spacing: 0.15px;
    }

    & .MuiFormLabel-root.MuiInputLabel-root.Mui-error {
      color: $error;
    }
    & .MuiFormLabel-root.MuiInputLabel-root.Mui-error.Mui-focused {
      color: $error;
    }
    & .MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
      color: $accent;
    }

    & .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled {
      color: $secondaryItems;
    }

    & .MuiInputBase-root {
      padding: 1px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.04));
      border-radius: 10px;
      overflow: hidden;

      &:hover {
        background: $mainText;
      }

      &.Mui-focused {
        background: $accent;
      }

      &.Mui-error {
        background: $error;
      }
    }
    & .MuiInputAdornment-root {
      position: absolute;
      right: 20px;
    }
  }
}

.input-pin {
  max-width: 120px;
  &.text-field__input .MuiInputBase-root input {
    text-align: center;
  }
  & input[type='password'],
  & input[type='tel'] {
    font-family: 'text-security-disc' !important;
    font-size: 20px;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none;
    -webkit-text-fill-color: #95979e;
    -webkit-font-smoothing: antialiased;
    -webkit-text-security: disc;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    letter-spacing: 3px;
  }
}

// .show-password {
//   & path {
//     fill: $secondAccentHover;
//   }
// }

// .hide-password {
//   // & path {
//   //   fill: $secondaryText;
//   // }
// }

input:-webkit-autofill {
  -webkit-text-fill-color: $mainText !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $buttonsBg inset !important;
}

.text-field__input {
  width: 85px;
  height: 52px;

  & input.otp-input {
    width: 100% !important;
    height: 100% !important;
  }
}

.Form__group.Form__group--otp {
  display: block;
  & > div {
    justify-content: space-between;

    & input[type='password'],
    & input[type='tel'] {
      font-family: 'text-security-disc' !important;
      font-size: 20px;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none;
      -webkit-text-fill-color: #95979e;
      -webkit-font-smoothing: antialiased;
      -webkit-text-security: disc;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      letter-spacing: 3px;
    }
  }
  & .i-m-error-wrap {
    margin-top: 5px;
  }
  & .i-m-error {
    font-size: 12px;
  }
}

.PasswordResendForm,
.ConfirmCodeForm {
  .Form__group:first-child {
    margin-bottom: 24px;
    margin-top: 32px;
  }
}

.ConfirmCodeForm .resend-code {
  background-color: transparent;
  color: $accent;
  text-decoration: none;
  &:hover {
    background-color: $accent;
    color: #fff;
  }
}

.ConfirmCodeForm .Form__group div,
.ParentControlForm .Form__group div {
  width: 100%;
  justify-content: space-between;
  gap: 12px;
  @media (min-width: $tablet) {
    gap: 20px;
  }

  & .text-field__input {
    border-radius: 10px;
    @media (min-width: $tablet) {
      max-width: 65px;
    }

    & input[type='password'],
    & input[type='tel'] {
      font-family: 'text-security-disc' !important;
      font-size: 20px;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none;
      -webkit-text-fill-color: #95979e;
      -webkit-font-smoothing: antialiased;
      -webkit-text-security: disc;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      letter-spacing: 3px;
    }

    & input {
      border-radius: 10px;
      background: $buttonsBg;
      color: $mainText;
      border: 1px solid rgba(255, 255, 255, 0.04);

      &:focus {
        border: 1px solid $accent;
      }

      &:hover {
        @media (min-width: $desktop-sd) {
          border: 1px solid $mainText;
        }
      }
    }
  }
}

.PasswordResetForm {
  .Form__group:not(:last-child) {
    margin-bottom: 24px;
    margin-top: 32px;
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
