@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/variables';

.ToolBar {
  //position: relative;
  display: flex;
  height: 100%;
  margin-left: auto;

  &__favorites {
    display: none;

    & > a {
      color: $mainText;

      &:hover, &.active {
        color: $accentHover;
        & svg path {
          fill: $accent !important;
        }
      }
    }
    @media (min-width: $tablet) {
      display: block;
    }
  }

  &__search {
    margin-right: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    & span {
      position: relative;
    }

    @media (min-width: $mobile-landscape) {
      position: relative;
    }

    @media (min-width: $tablet) {
      margin: 0 1.5rem;
    }

    span {
      display: flex;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      transition: all 0.25s;
      border-radius: 50%;
      &:hover {
        background: $buttonsBg;
        path {
          fill: $mainText;
        }
      }
    }
  }

  &__localization {
    position: relative;
    margin-left: 8px;
    margin-right: 20px;
    min-width: rem(51px);

    @media (min-width: $tablet) {
      margin-left: 0;
    }

    .Loader {
      width: 1.5rem;
      height: 1.5rem;
      top: calc(50%);
      left: calc(50%);
      transform: translate(-50%, -50%);
    }
  }

  &__item {
    margin-left: 1rem;

    @media (min-width: $tablet) {
      margin-left: 1.5rem;
    }
  }

  &__subscribe {
    a {
      span {
        color: $orange;
        padding-left: 8px;
      }

      &:hover {
        color: darken($orange, 10%);

        span {
          color: darken($orange, 10%);
        }

        svg path {
          fill: darken($orange, 10%) !important;
        }
      }
    }
  }

  &__avatar {
    &:hover {
      span {
        color: $orange !important;
      }
    }

    span {
      width: 2rem;
      height: 2rem;
      transition: $nav-link-transition;
    }
  }

  &__login {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & .ToolBar__avatar {
      background: none;
      padding: 3px;
      border-radius: 50%;

      &:hover {
        background: $buttonsBg;
      }
    }

    & a {
      background: $secondAccent;
      justify-content: flex-end;
      padding: 8px;
      border-radius: 6px;
      color: $mainText;
      height: initial;
      @include typography(subtitleSm, $bold);

      @media (min-width: $tablet) {
        padding: 10px 16px;
      }

      &:hover {
        background: $secondAccentHover;
        color: $mainText !important;
      }
    }

    @media (min-width: $desktop-sd) {
      transition: background-color $transition;

      &:hover,
      &:active {
        .ProfileDropdown__wrap {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
