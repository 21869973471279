@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.SubscriptionCard {
  $self: &; // ref to component (&)
  position: relative;
  display: flex;
  background-color: #303c5c;
  margin-bottom: 1.5rem;
  padding: 1.5rem 2rem;
  flex-direction: column;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: $desktop-sd) {
    padding: 2.5rem 3rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__info {
    width: 100%;

    @media (min-width: $desktop-sd) {
      padding: 0 rem(20px) 0 0;
    }
  }

  .Btn {
    max-width: rem(240px);
    width: 100%;
  }

  &__titleWrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__title {
    display: flex;
    align-items: center;
    margin: 0 1rem 1rem 0;

    &::before {
      content: '';
      width: 2rem;
      height: 2rem;
      margin-right: rem(12px);
      display: block;
      background-size: cover;
      min-width: 2rem;

      @media (min-width: $desktop-sd) {
        width: 3rem;
        height: 3rem;
        min-width: 3rem;
      }
    }
  }

  &__description {
    font-size: rem(14px);
    color: $grey;
    line-height: 1.75;
    margin-bottom: 1rem;

    @media (min-width: $desktop-sd) {
      margin-bottom: 0;
    }

    &-line {
      &-until {
        font-size: rem(16.5px);
        font-weight: bold;
        color: $white;
      }
    }

    span {
      position: relative;

      &:not(:last-of-type) {
        &::after {
          content: ', ';
        }
      }
    }
  }

  // style decoration
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: rem(4px);

    @media (min-width: $desktop-sd) {
      width: rem(8px);
    }
  }

  &:nth-of-type(3n + 1) {
    #{ $self }__title {
      &::before {
        background-image: url(../../../assets/images/subscriptions/tariff1.svg);
      }
    }

    &::after {
      background-color: $aqua;
    }
  }

  &:nth-of-type(3n + 2) {
    #{ $self }__title {
      &::before {
        background-image: url(../../../assets/images/subscriptions/tariff2.svg);
      }
    }

    &::after {
      background-color: $violet;
    }
  }

  &:nth-of-type(3n + 3) {
    #{ $self }__title {
      &::before {
        background-image: url(../../../assets/images/subscriptions/tariff3.svg);
      }
    }

    &::after {
      background-color: $orange;
    }
  }

  &:nth-of-type(n + 3) {
    #{ $self }__title {
      &::before {
        background-image: url(../../../assets/images/subscriptions/tariff3.svg);
      }
    }
  }

  &__label {
    display: inline-flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: rem(7px) rem(12px);
    color: $orange;
    border: 1px solid $orange;
    border-radius: $b-radius;
    font-size: rem(12px);
    line-height: $line-height;
    font-weight: 500;

    svg {
      margin-right: 0.625rem;
    }
  }
}
