@import 'variables';
@import 'mixins';

.Skeleton {
  & > * {
    position: relative;
    overflow: hidden;
    opacity: 0.5;
    background-color: rgba(54, 54, 54, 0.5);
    border-radius: 10px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: -150px;
      top: 0;
      height: 100%;
      width: 150px;
      background: linear-gradient(
        90deg,
        rgba(103, 103, 103, 0) 0%,
        rgba(61, 61, 61, 0) 33.33%,
        #3d3d3d 74.47%,
        rgba(59, 59, 59, 0) 100%
      );
      animation: load 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
