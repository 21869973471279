@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.ChannelsSubscriptionList {
  .ChannelsGrids {
    position: relative;
    min-height: rem(150px);
    & .Loader {
      position: fixed;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
    }
  }
}

.ChannelGenresTitle {
  @include typography(h2, $bold);
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.MuiAppBar-root.AppTabs {
  background-color: transparent;
  margin-bottom: 24px;
  box-shadow: none;
  position: relative;
  padding-right: 16px;
  margin-top: 14px;
  @media (min-width: $tablet) {
    margin-bottom: 32px;
    padding-right: 32px;
  }
  @media (min-width: $desktop-hd) {
    padding-right: 0;
    margin-bottom: 32px;
  }
  &:not(.with-scroll-buttons) .SliderButtons {
    display: none;
  }
  .MuiTabs-scroller {
    scroll-behavior: smooth;
    overflow-scrolling: touch;
    box-shadow: inset 0 -2px 0 rgba(255, 255, 255, 0.1);
  }
}

.MuiTab-root.Mui-selected.AppTabs__tab {
  color: $accent;
}

.MuiTab-root.AppTabs__tab {
  color: $mainText;
  padding: 16px 0 12px;
  margin-right: 32px;
  text-transform: none;
  min-width: initial;
  letter-spacing: normal;
  @include typography(h4, $regular);

  &:hover {
    color: $accentHover;
  }
}

.AppTabs .MuiTabs-indicator {
  height: 2px;
  background-color: $accent;
  border-radius: 2px;
}

.AppTabs__tab-content .MuiBox-root {
  padding: 0;
}

.AppTabs .MuiTabs-root .MuiButtonBase-root {
  & svg {
    height: 40px;
    fill: #ffffff;
  }
}

.MuiTabScrollButton-root.MuiTabScrollButton-horizontal.MuiTabs-scrollButtons {
  width: 40px;
  height: 40px;
  position: absolute;
  background-color: $buttonsBg;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: none;

  @media (min-width: $desktop-sd) {
    display: flex;
  }

  &:hover {
    border: 1px solid $accent;
  }

  &.Mui-disabled {
    opacity: 0.5;
  }
}

.AppTabs .MuiTabs-root {
  position: relative;
  overflow: visible;
  & .SliderButtons {
    position: absolute;
    display: none;
    top: -75px;
    right: 0;
    @media (min-width: $desktop-sd) {
      display: flex;
    }
    &:first-child {
      display: none;
    }
  }
}
