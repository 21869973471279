@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';
@import '~react-multi-carousel/lib/styles.css';

.MainBanner {
  margin-top: 12px;
  width: 100%;
  position: relative;
  margin-bottom: 65px;
  @media (min-width: $tablet) {
    margin-top: 24px;
    margin-bottom: 35px;
  }
  @media (min-width: $desktop-sd) {
    margin-bottom: 85px;
  }
  // height: calc(100vw / 0.71);
  // @media (min-width: 426px) {
  //   height: calc(100vw / 1.28);
  // }
  // @media (min-width: 768px) {
  //   height: calc(100vw / 3.2);
  // }

  &__btns-wrap {
    display: flex;
    align-items: center;
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }

  &__item {
    width: calc(100vw - 32px);
    max-width: 1600px;
    position: relative;
    padding: 1px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.3)
    );
    border-radius: 10px;

    @media (min-width: $tablet) {
      width: calc(100vw - 64px);
    }

    @media (min-width: $desktop-hd) {
      width: calc(100vw - 128px);
    }

    &-logo {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
    }

    img {
      left: 0;
      top: 0;
      width: 100%;
      object-fit: cover;
      display: block;
      border-radius: 10px;
    }

    &-content {
      position: relative;
      color: $white;
      padding: 3rem 1rem 0;

      @media (min-width: $desktop-sd) {
        position: absolute;
        left: 188px;
        top: 33%;
        max-width: 480px;
        padding: 0;
      }
    }

    &-title {
      font-weight: 700;
      font-size: 2rem;
      line-height: $line-height;
      text-align: left;
      margin-bottom: 1.25rem;

      @media (min-width: $desktop-sd) {
        font-size: 4rem;
        margin-bottom: 2rem;
      }
    }

    &-buttons {
      display: flex;

      button {
        display: flex;
        align-items: center;
        padding: 1rem;
        height: 3rem;
        width: 151px;
        margin-right: 1.5rem;
        border: 0;
        font-size: 1rem;
        line-height: $line-height;
        border-radius: $b-radius;
        color: $white;

        &:last-of-type {
          margin-right: 0;
        }

        @media (min-width: $desktop-sd) {
          height: 56px;
          padding: 1rem 1.5rem;
        }

        svg {
          margin-right: 6px;
        }
      }

      .btn-watch {
        background-color: $aqua;
      }

      .btn-subscribe {
        width: 186px;
        background-color: $orange;
      }
    }
  }

  .Dots {
    margin-bottom: rem(12px);

    @media (min-width: $desktop-sd) {
      margin-bottom: 3rem;
    }

    button {
      border: 0;
      background-color: $white;
      height: 8px;
      width: 8px;
      opacity: 0.9;
      margin: 0;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    }

    .react-multi-carousel-dot {
      margin: 0 6px;

      &--active {
        button {
          transform: scale(2);
        }
      }
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    z-index: 3;
    margin-top: -1.5rem;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    display: none;
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    transition: all 0.25s;

    &:hover {
      background: rgba(0, 0, 0, 0.8);
    }

    @media (min-width: $desktop-sd) {
      display: flex;
    }

    &--right {
      right: 92px;
    }
    &--left {
      left: 92px;
    }
  }
}

.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -22px;
}

.swiper-pagination-bullet {
  border: 0;
  background-color: $secondaryItems;
  height: 10px;
  width: 10px;
  opacity: 1;

  &:hover {
    background-color: $accentHover;
  }
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: $mainText;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 6px;
}

.swiper {
  position: initial;
  height: 59vw;

  @media (min-width: 426px) {
    height: 37vw;
  }
  max-height: 627px;
}
.swiper-slide {
  width: initial;
}

.slider__slide-skeleton {
  margin: 0 auto;
  width: calc(100vw - 32px);
  height: calc(64vw - 20.48px);
  max-width: 1600px;
  max-height: 625px;

  position: relative;
  overflow: hidden;
  opacity: 0.5;
  background-color: rgba(54, 54, 54, 0.5);
  border-radius: 10px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(
      90deg,
      rgba(103, 103, 103, 0) 0%,
      rgba(61, 61, 61, 0) 33.33%,
      #3d3d3d 74.47%,
      rgba(59, 59, 59, 0) 100%
    );
    animation: load 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }

  @media (min-width: 426px) {
    height: calc(39vw - 12.48px);
  }
  @media (min-width: $tablet) {
    width: calc(100vw - 64px);
    height: calc(39vw - 24.96px);
  }
}
