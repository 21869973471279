@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.Tabs {
  display: flex;
  margin: 14px 0 32px;
  overflow-x: scroll;
  white-space: nowrap;
  overflow-scrolling: touch;

  @media (min-width: $desktop-sd) {
    margin-bottom: 44px;
  }

  &Btn {
    color: $mainText;
    position: relative;
    @include typography(h4, $regular);
    padding: 12px 0;
    margin-right: 32px;
    cursor: pointer;
    transition: all $transition;

    &::before {
      content: '';
      display: block;
      height: 2px;
      background: #2c2c2c;
      position: absolute;
      // right: 0;
      // left: 0;
      bottom: 0;
      min-width: calc(100% + 32px);
    }

    &:last-child {
      &::before {
        min-width: calc(100%);
      }
    }

    &:hover {
      color: $accentHover;
    }

    &.selected {
      color: $accent;

      &::after {
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 2;
        background: $accent;
        border-radius: 1px;
        box-shadow: inset 0px -1px 0px $accent;
      }

      &:hover {
        color: $accentHover;
      }
    }
  }
}

.TabContent {
  padding-right: 16px;

  & h2 {
    @include typography(h2, $bold);
  }

  @media (min-width: $tablet) {
    padding-right: 32px;
  }
  @media (min-width: $desktop-hd) {
    padding-right: 0;
  }
}

.ProfileContent .TabContent {
  padding: 0;
}

.videolist__wrap:not(:first-child) {
  margin-top: 64px;
}
