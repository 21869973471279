@import 'variables';
@import 'mixins';

button {
  cursor: pointer;
}

.Btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 2.5rem;
  border: 0;
  text-align: center;
  font-size: 1rem;
  line-height: 1.1875;
  font-weight: 500;
  border-radius: 10px;
  padding: 0 0.5rem;
  transition: 0.25s background-color, color;

  @media (min-width: $desktop-sd) {
    height: 3rem;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed !important;
    background: $disabledButton;
  }

  &--primary {
    background-color: $accent;
    color: $white;

    &:hover {
      background-color: $accentHover;
    }
  }

  &--aqua {
    background-color: $aqua;
  }

  &--orange {
    background-color: $secondAccent;
    color: $white;

    &:hover {
      background-color: $secondAccentHover;
    }
  }

  &--orange-unfilled {
    background: transparent;
    color: $secondAccent;
    border: 1px solid $secondAccent;

    &:hover {
      background: $secondAccent;
      color: $mainText;
    }
  }

  &--violet {
    background-color: $violet;
    color: $white;

    &:hover,
    &:focus {
      background-color: darken($violet, 10%);
    }
  }

  &--secondary {
    background-color: transparent;
    color: $white;
    border: 1px solid $white;

    svg {
      margin-right: rem(10px);
    }

    &.active,
    &:hover {
      background-color: $white;
      color: $aqua;

      path {
        fill: $aqua;
      }
    }
  }

  &--icon {
    background: none;
    border: 0;
    transition: all $transition;
    width: 1.5rem;
    height: 1.5rem;

    &.active {
      path {
        fill: $orange;
      }
    }
  }
}
