@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

/* Modal */
.Modal {
  position: relative;
  font: $primary-font;
  padding: 1rem;

  max-height: 90%;
  background-color: $blocksBg;
  box-shadow: 0 8px 24px rgba(37, 42, 49, 0.24), 0 4px 8px rgba(37, 42, 49, 0.16);
  border-radius: $b-radius;
  transition: all $transition;
  border-radius: 10px;
  width: 328px;

  @media (min-width: $tablet) {
    width: 470px;
    height: max-content;
    padding: 2rem;
    overflow-y: initial;
  }

  &__wrap {
    padding: 1px;
    border-radius: 10px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.3)
    );
  }

  &__close {
    color: $mainText;
    cursor: pointer;
    position: absolute;
    right: 0rem;
    top: -2.5rem;
    border-radius: 50%;
    background-color: $buttonsBg;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg path {
      fill: $mainText;
    }

    @media (min-width: $tablet) {
      right: 2rem;
      top: 2rem;
      right: 1rem;
      top: 1rem;
    }

    &:hover {
      path {
        fill: $secondAccent;
      }
    }
  }

  &__title {
    padding: 0 1.25rem;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    @include typography(h3, $bold);
    color: $mainText;
  }

  &__text {
    width: 100%;
    color: $violet-dark;
    font-size: 14px;
    line-height: 1.4;
    margin: 12px auto;
    text-align: center;

    a {
      color: $orange;
      text-decoration-skip-ink: none;

      &:hover {
        text-decoration: underline dashed;
      }
    }
  }

  &__description {
    margin-top: 16px;
    @include typography(subtitle, $regular);
    color: $mainText;
  }

  strong {
    font-weight: 700;
  }

  &__socials {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &::before {
      content: '';
      width: 50%;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      border-bottom: 1px #d2d2d2 solid;
    }

    p {
      opacity: 0.5;
    }

    &-btn {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 47%;
      height: 44px;
      background-color: $white;
      border-radius: $b-radius;
      border: none;
      transition: 0.25s background-color;

      &:hover {
        background-color: darken($white, 10%);
      }

      span {
        font-weight: bold;
        color: #252a31;
        margin-left: 8px;
      }
    }

    .Loader {
      position: relative;

      span {
        border-color: $violet;
      }
    }
  }
}

.is-modal-opened {
  overflow: hidden !important;
  .Modal {
    opacity: 1;
    visibility: visible;
    //transform: translateY(0);
    transition: all $transition;
    transition-delay: 1s;
  }
}

.SimpleModal {
  p {
    color: $violet-dark;
    margin-bottom: 1.5rem;
    line-height: 1.5;
  }

  .subtitle {
    margin: 1rem 0 rem(12px);
    color: $orange;
    line-height: $line-height;
  }

  .Btn {
    width: 100%;
  }
}

/* Modal overlay */
.ModalOverlay {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(1px);
  overflow: auto;
  background: $overlay;
  backdrop-filter: blur(5px);
}
