@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.ProfileDropdown {
  border-radius: 4px;
  background-color: $blocksBg;
  padding: 6px;

  &__wrap {
    padding: 1px;
    border-radius: 5px;
    width: max-content;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.04));
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 5px;
    opacity: 0;
    visibility: hidden;
    transition: all $transition;
  }

  & a {
    background: none;
    color: $mainText;

    &:hover {
      background: none;
      color: $mainText;
    }
  }

  &__title,
  &__logout {
    color: $mainText;
    @include typography(subtitle, $regular);
    transition: color $transition;

    &.active,
    &:hover {
      color: $mainText;
      background: none;
    }
  }

  ul {
    padding: 1rem 0;
  }

  li {
    margin-right: 0 !important;
    height: initial;

    a {
      display: block;
      font-size: 1rem;
      font-weight: 400;
      color: $white;
      text-transform: none;

      &.active,
      &:hover {
        color: $mainText;
        background-color: $aqua;
      }
    }
  }

  &__header {
    margin-bottom: 2px;
    border-radius: 5px;
    transition: all 0.25s;

    &:hover {
      background: $buttonsBg;
    }

    .ProfileDropdown__title {
      padding: 10px 8px;
      display: block;
      @include typography(subtitle, $regular);
    }
  }

  &__footer {
    margin-top: 5px;

    position: relative;
    border-radius: 5px;
    transition: all 0.25s;

    & span {
      padding: 10px 8px;
      display: block;
    }

    &:hover {
      background: $buttonsBg;
    }

    &::before {
      content: '';
      display: block;
      right: 0;
      left: 0;
      top: -3px;
      position: absolute;
      border-top: 1px solid $disabledButton;
    }
  }
}
