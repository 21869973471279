@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/variables';

.Burger {
  $self: &; // ref to component (&)

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  z-index: 1;
  margin-right: 20px;
  background: transparent;
  border: none;
  cursor: pointer;

  @media (min-width: $tablet) {
    margin-right: 30px;
  }

  &--opened {
    #{ $self }__line {
      &:first-child {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }

  &__line {
    position: relative;
    width: 100%;
    height: rem(3px);
    transform-origin: 1px;
    background-color: $white;
    transition: all 0.3s linear;
    border-radius: 0.5rem;
    height: 2px;

    &:first-child {
      transform: rotate(0);
    }

    &:nth-child(2) {
      opacity: 1;
    }

    &:nth-child(3) {
      transform: rotate(0);
    }
  }
}
