@import '../../../../assets/styles/variables';

.NavBar {
  $self: &; // ref to component (&)

  & > ul {
    display: flex;
    height: 100%;
  }

  li:not(:last-of-type) {
    margin-right: 1rem;
  }
}
