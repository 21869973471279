@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.SliderButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: $buttonsBg;
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  font-size: 0;

  border: 1px solid rgba(255, 255, 255, 0.04);

  &:hover {
    border: 1px solid $accent;
  }

  &[disabled] {
    opacity: 0.3;
    &:hover {
      border: none;
    }
  }

  &[data-direction='prev'],
  &.prev {
    margin-right: rem(12px);
    background-image: url('../../assets/images/movies/chevron_left.svg');
  }

  &[data-direction='next'],
  &.next {
    background-image: url('../../assets/images/movies/chevron_right.svg');
  }
}
