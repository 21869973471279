@import '../../../assets/styles/variables';

.PlayButton {
  position: relative;
  display: grid;
  place-content: center;
  border: 0;
  border-radius: 50%;
  background-color: white;
  font-size: 0;
  transition: background-color $transition;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    background-image: url('../../../assets/images/movies/play_arrow.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
  }
}
