@import '../../assets/styles/variables';

.rocket-log {
  position: fixed;
  padding: 12px 16px;
  border-width: 3px 3px 0px 3px;
  border-style: solid;
  border-color: #d93131;
  border-radius: 3px 3px 0px 0px;
  background: rgba(0, 0, 0, 0.9);
  color: #ffffff;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  font-family: $font-family;
}
