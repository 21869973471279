@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.CardsSlider {
  margin: 0 0 44px;
  overflow: hidden;

  @media (min-width: $desktop-sd) {
    margin: 0 0 64px;
  }

  .CardsItem,
  .CardsItemSkeleton {
    margin: 0 4px;
    @media (min-width: $tablet) {
      margin: 0 10px;
    }
    @media (min-width: $desktop-hd) {
      margin: 0 14px;
    }
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding-right: 16px;
    @include typography(h2, $bold);
    min-height: 42px;

    & a {
      @include typography(h2, $bold);
    }

    @media (min-width: $tablet) {
      padding-right: 32px;
    }

    @media (min-width: $desktop-sd) {
      margin-bottom: 1.5rem;
    }
    @media (min-width: $desktop-hd) {
      padding-left: 0px;
    }

    a {
      color: currentColor;
      transition: all 0.25s;

      &:hover {
        color: $aqua;
        text-decoration: underline;
      }
    }

    .show-all-btn {
      @include typography(subtitle, $medium);
      color: $accent;
      text-decoration: none;
      border: 1px solid $accent;
      padding: 10px 8px;
      border-radius: 8px;
      margin-left: 16px;
      flex-shrink: 0;

      @media (min-width: $tablet) {
        padding: 10px 14px;
      }
      @media (min-width: $desktop-sd) {
        display: flex;
        align-items: center;
        margin-right: 90px;
      }

      &:hover {
        border-color: $accentHover;
        color: $white;
        background-color: $accentHover;
        text-decoration: none;
      }
    }
  }

  &__content {
    position: relative;
    margin-left: -4px;
    @media (min-width: $tablet) {
      margin-left: -10px;
    }
    @media (min-width: $desktop-hd) {
      margin: 0 -14px;
    }
  }

  // Scroll list (instead slider on 0-1023px of viewport)
  &__scroll-list {
    overflow-x: scroll;
    white-space: nowrap;
    overflow-scrolling: touch;
    margin-right: -1rem;

    .CardsItem,
    .CardsItemSkeleton {
      margin-right: 1rem;
      margin-left: 0;
      display: inline-block;
      width: 120px;
    }
  }

  &__button-group {
    display: none;
    @media (min-width: $desktop-sd) {
      display: initial;
    }
    position: absolute;
    right: 10px;
    top: -2.7rem;

    @media (min-width: $desktop-sd) {
      position: absolute;
      top: -4rem;
    }

    @media (min-width: $desktop-sd) {
      right: 14px;
    }
  }
}
